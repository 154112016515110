import { Injectable } from '@angular/core';
import { action, makeObservable, observable } from 'mobx';
import {
  BreadcrumbItem,
  DetailsTab,
  ListItemDTO,
} from '../../../../core/modals/details.modal';
import { AuditDetailsDTO } from '../dto/audit.dto';
import { DetailsBaseStore } from '../../../../core/store/details-base.store';
import { AuditDetailsConfig } from '../config/audit-details.config';
import { AuditService } from '../services/audit.service';
import { AuditConversion } from '../conversion/audit.conversion';
import { pieChartData } from '../../../../core/modals/chart.modal';
import { UserMinimalDTO } from '../../../organization/users/dto/user.dto';
import { AuditableItemListDTO } from '../../audit-universe/dto/auditable-item.dto';
import { AuditAuditableItemService } from '../services/audit-auditable-item.service';
import { AuditPlanAuditableItemConversion } from '../../audit-plan/conversion/audit-plan-auditable-item.conversion';

@Injectable({ providedIn: 'root' })
export class AuditDetailsStore extends DetailsBaseStore<AuditDetailsDTO> {
  tabs: DetailsTab[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  auditScheduleSummaryChartData: pieChartData[] = [];
  auditFindingSummaryChartData: pieChartData[] = [];

  auditMembers: UserMinimalDTO[] = [];
  auditableItems: AuditableItemListDTO[] = [];
  scope: ListItemDTO[] = [];
  auditDepartments: string;

  constructor(
    private auditConversion: AuditConversion,
    private auditDetailsConfig: AuditDetailsConfig,
    private auditService: AuditService,
    private auditAuditableItemService: AuditAuditableItemService,
    private auditPlanAuditableItemConversion: AuditPlanAuditableItemConversion
  ) {
    super();
    makeObservable(this, {
      tabs: observable,
      breadcrumbs: observable,
      convertResponseToDTO: action,
    });
  }

  override get service(): any {
    return this.auditService;
  }

  override initialize(): void {
    this.tabs = this.auditDetailsConfig.auditTabs;
    this.breadcrumbs = this.auditDetailsConfig.breadcrumbs;
  }

  fetchAuditScheduleSummaryChart(auditId: number) {
    this.auditService
      .getAuditScheduleSummaryChart(auditId)
      .subscribe((response) => {
        const { pending_count, completed_count } = response;
        if (pending_count == 0 && completed_count == 0) {
          this.auditScheduleSummaryChartData = [];
          return;
        }
        this.auditScheduleSummaryChartData =
          this.auditConversion.convertResponseToAuditScheduleSummaryChartDTO(
            response
          );
      });
  }

  fetchAuditFindingSummaryChart(auditId: number) {
    this.auditService
      .getAuditFindingSummaryChart(auditId)
      .subscribe((response) => {
        if (response.length == 0) {
          this.auditFindingSummaryChartData = [];
          return;
        }
        this.auditFindingSummaryChartData =
          this.auditConversion.convertResponseToAuditFindingSummaryChartDTO(
            response
          );
      });
  }

  convertResponseToDTO(response: any) {
    const auditDetails = this.auditConversion.resToDetails(response);
    const { title } = auditDetails.audit_plan;
    this.breadcrumbs[1].label = `${title}`;

    //prepare audit members
    this.auditMembers = auditDetails.audit_plan.members;
    if (auditDetails.audit_plan.team_lead) {
      this.auditMembers.push(auditDetails.audit_plan.team_lead);
    }
    //make unique
    this.auditMembers = Array.from(
      new Map(
        this.auditMembers.map((member: UserMinimalDTO) => [member.id, member])
      ).values()
    );

    return auditDetails;
  }

  prepareAuditableItems(auditId: number) {
    const defaultPageSize = 1000;

    this.auditAuditableItemService
      .list(auditId, undefined, undefined, 1, defaultPageSize)
      .subscribe({
        next: (response) => {
          this.auditableItems = this.auditPlanAuditableItemConversion.resToList(
            response.items
          );

          //Auditee Departments
          this.auditDepartments = Array.from(
            new Set(
              this.auditableItems.map(
                (auditableItem: any) => auditableItem.responsible_unit
              )
            )
          ).join(', ');
          //Scope
          this.scope = this.auditableItems.map(
            (auditableItem: any) =>
              ({
                id: auditableItem.id,
                item: auditableItem.title,
              } as ListItemDTO)
          );
        },
        error: (error) => {
          console.error('Error fetching auditable items', error);
        },
      });
  }

  override resetEntireChildState(): void {
    this.auditMembers = [];
    this.auditableItems = [];
    this.scope = [];
    this.tabs = [];
    this.breadcrumbs = [];
    this.auditScheduleSummaryChartData = [];
  }
}
