import { Injectable } from '@angular/core';
import {
  BreadcrumbItem,
  DetailsTab,
} from '../../../../core/modals/details.modal';

@Injectable({ providedIn: 'root' })
export class AuditDetailsConfig {
  breadcrumbs: BreadcrumbItem[] = [
    { label: 'Audits', url: '/audit/audits' },
    { label: '', url: '' },
  ];

  auditTabs: DetailsTab[] = [
    {
      link: '/audit/audits/:id/overview',
      icon: 'overview-tab', //TODO : add icon for all below
      label: 'Overview',
      isActive: true,
    },
    {
      link: '/audit/audits/:id/auditable-items',
      icon: 'overview-tab',
      label: 'Auditable Items',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/meetings',
      icon: 'overview-tab',
      label: 'Meetings',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/working-paper',
      icon: 'associated-items-tab',
      label: 'Working Paper',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/document-request',
      icon: 'associated-items-tab',
      label: 'Document Request',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/audit-schedules',
      icon: 'associated-items-tab',
      label: 'Audit Schedules',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/checklist',
      icon: 'risk-analysis-tab',
      label: 'Checklist',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/draft-report',
      icon: 'rca-tab',
      label: 'Draft Report',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/final-report',
      icon: 'risk-analysis-tab',
      label: 'Final Report',
      isActive: false,
    },
    {
      link: '/audit/audits/:id/audit-findings',
      icon: 'risk-analysis-tab',
      label: 'Audit findings',
      isActive: false,
    },
  ];
}
